import {bindable, customElement, inject} from 'aurelia-framework';
import moment from "moment";
import {Client} from "../../../api/client";
import _ from "lodash";

import "./amadeus-pnr-display.less";

@customElement('amadeus-pnr-display')
@inject(Client)
export class AmadeusPnrDisplay {

    @bindable pnr;
    @bindable searchResult;
    @bindable params;
    @bindable showEdit;
    @bindable short;

    text = 'NO DATA';
    client;

    editConfig = {
        type: 'view',
        viewId: 'tourism-flight/pnr-display',
        modal: true,
        icon: 'pencil',
        buttonClass: 'btn btn-link'
    };

    constructor(client) {
        this.client = client;
    }

    pnrChanged() {
        this.text = '';

        if (!this.pnr) {
            return;
        }

        this.text += this.pnr.pnrHeader.reservationInfo.reservation.controlNumber;

        if (this.short) {
            return;
        }

        console.debug(this.pnr);

        if (this.pnr.securityInformation) {
            this.text += ' ';
            if (this.pnr.securityInformation.responsibilityInformation) {
                this.text += this.pnr.securityInformation.responsibilityInformation.officeId;
            }
            if (this.pnr.secondRpInformation) {
                this.text += '/' + this.pnr.secondRpInformation.creationOfficeId;
            }
        }

        const lines = [];
        let line;

        if (!Array.isArray(this.pnr.travellerInfo)) {
            this.pnr.travellerInfo = [this.pnr.travellerInfo];
        }

        for (let info of this.pnr.travellerInfo) {

            line = info.passengerData.travellerInformation.traveller.surname;

            switch (info.elementManagementPassenger.segmentName) {

                case 'NG':
                    line += ' NM: ' + info.passengerData.travellerInformation.traveller.quantity;

                    for (let details of info.passengerData.groupCounters.quantityDetails) {
                        line += ' ' + details.unitQualifier + ': ' + details.numberOfUnit + ' ';
                    }
                    break;

                case 'NM':
                    line += '/' + info.passengerData.travellerInformation.passenger.firstName;
                    break;
            }

            lines[info.elementManagementPassenger.lineNumber] = line;
        }

        let itineraryInfo = [];
        if (this.pnr.originDestinationDetails) {
            if (_.isArray(this.pnr.originDestinationDetails.itineraryInfo)) {
                itineraryInfo = this.pnr.originDestinationDetails.itineraryInfo;
            } else {
                itineraryInfo = [ this.pnr.originDestinationDetails.itineraryInfo ];
            }
        }

        for (let info of itineraryInfo) {

            line = '';

            switch (info.elementManagementItinerary.segmentName) {
                case 'AIR':
                    if (!info.travelProduct) {
                        break;
                    }

                    const details = info.travelProduct.productDetails;
                    const product = info.travelProduct.product;
                    const company = info.travelProduct.companyDetail;

                    if (company) {
                        line += company.identification + ' ';
                    }

                    if (details) {

                        line += details.identification + ' ';

                        if (details.classOfService) {
                            line += details.classOfService + ' ';
                        }

                        if (product) {

                            line += this._convertDate(product.depDate) + ' ' +
                                info.flightDetail.productDetails.weekDay + ' ' +
                                info.travelProduct.boardpointDetail.cityCode + ' ' +
                                info.travelProduct.offpointDetail.cityCode + ' ';

                            if (info.relatedProduct) {
                                line += info.relatedProduct.status + info.relatedProduct.quantity + ' ';
                            }

                            line += product.depTime + ' ' +
                                product.arrTime;

                            if (product.dayChangeIndicator) {
                                line += '+' + product.dayChangeIndicator;
                            }
                        }
                    }

                    if (info.distributionMethod && info.distributionMethod.methodCode) {
                        line += ' ' + info.distributionMethod.methodCode;
                    }
            }

            lines[info.elementManagementItinerary.lineNumber] = line;
        }

        const dataElementsIndiv = (this.pnr.dataElementsMaster && this.pnr.dataElementsMaster.dataElementsIndiv) || [];
        for (let other of dataElementsIndiv) {

            if (!other.elementManagementData.reference) {
                continue;
            }

            if (
                'OT' !== other.elementManagementData.reference.qualifier ||
                ('AP' !== other.elementManagementData.segmentName && 'TK' !== other.elementManagementData.segmentName)
            ) {
                continue;
            }

            console.log(other);

            line = other.elementManagementData.segmentName + ' ';

            switch (other.elementManagementData.segmentName) {

                case 'AP':
                    line += other.otherDataFreetext.longFreetext;
                    break;

                case 'TK':
                    line += other.ticketElement.ticket.indicator + ' ' + other.ticketElement.ticket.date;
                    break;
            }

            lines[other.elementManagementData.lineNumber] = line;

        }

        for (let line = 0; line < lines.length; line++) {
            if (lines[line]) {
                this.text += '\n';

                if (10 > line) {
                    this.text += ' ';
                }

                this.text += line + ' ' + lines[line];
            }
        }
    }

    searchResultChanged() {
        this.text = '';

        if (!this.searchResult) {
            return;
        }

        this.text += this.searchResult.reservationInfo.reservation.companyId + ' ' +
            this.searchResult.reservationInfo.reservation.controlNumber;

        if (this.searchResult.productInfo) {
            this.text += ' ' + this.searchResult.productInfo.product.identification;
            if (this.searchResult.relatedProduct) {
                this.text += this.searchResult.relatedProduct.quantity;
            }
        }

        if (this.searchResult.travellerInformation) {
            this.text += '\n' + this.searchResult.travellerInformation.traveller.surname;
        }

        if (this.searchResult.travelProduct) {
            this.text += '\n';

            if (this.searchResult.travelProduct.companyDetail) {
                this.text += this.searchResult.travelProduct.companyDetail.identification + ' ';
            }

            if (this.searchResult.travelProduct.product) {
                this.text += this._convertDate(this.searchResult.travelProduct.product.depDate) + ' ';
            }

            if (this.searchResult.travelProduct.boardpointDetail) {
                this.text += this.searchResult.travelProduct.boardpointDetail.cityCode + ' ';
            }

            if (this.searchResult.travelProduct.offpointDetail) {
                this.text += this.searchResult.travelProduct.offpointDetail.cityCode + ' ';
            }
        }
    }

    paramsChanged() {
        if (!this.params || !this.params.hasOwnProperty('id') || !this.params.hasOwnProperty('modelId') || 'tourism-flight/pnr' !== this.params.modelId) {
            return;
        }

        this.text = 'LOADING …';
        console.debug(this.params);
        this.client.get(this.params.modelId + '/' + this.params.id).then(pnr => this.pnr = pnr.data);
    }

    _convertDate(date) {
        console.debug(date, moment(date, 'DDMMYY').format('DDMMM'));
        return moment(date, 'DDMMYY').format('DDMMM').toUpperCase().replace(/[\. ]/, '');
    }
}
